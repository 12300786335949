



























































































import Vue from 'vue'
import Component, { mixins } from 'vue-class-component';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import ReportingFormTable from '@/modules/genprox/modules/reporting/components/ReportingFormTable.vue';
import StaticReportsTable from '@/modules/genprox/modules/reporting/components/StaticReportsTable.vue';
import ReportingFilters from '@/modules/genprox/modules/reporting/components/ReportingFilters.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniContextPicker from '@/components/SygniContextPicker.vue';
import {SygniAccess} from "@/shared/mixins/AccessMixin";
import { REDIRECTED_PATH } from '@/modules/genprox/genprox.routing';
import SectionTable from '@/modules/genprox/modules/reporting/components/SectionTable.vue';
import ReportingTable from '@/modules/genprox/modules/reporting/components/ReportingTable.vue';
import SygniTextArea from '@/components/inputs/SygniTextArea.vue';
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import ReportingFilesTable from '../components/ReportingFilesTable.vue';
import AttachmentModal from '@/modules/genprox/modules/fund/modules/capital-rise/modules/templates/components/AttachmentModal.vue';
import moment from 'moment';
// import _ from 'lodash';
import { Watch } from 'vue-property-decorator/lib/decorators/Watch';
import _ from 'lodash';
import Utils from '@/modules/shared/utils/utils';
import { BACKEND_BASE_URL } from '@/shared/consts';

@Component({
  components: { SygniContainerTitle, ReportingFormTable, AttachmentModal, StaticReportsTable, ReportingFilters, SygniLoader, SygniContextPicker, SectionTable, ReportingTable, SygniTextArea, SygniRadio, SygniInput, SygniSelect, ReportingFilesTable, SygniRectButton },
})
export default class reportingModule extends mixins(Vue, SygniAccess) {
  viewedReportingId: string = ''
  isLoading: boolean = false;
  editMode: boolean = false;
  periodType: string = '';
  rangeType: string = '';
  activeCol: number = 0;
  dataToSave: any = {};
  editableValues: any = {};
  aggregated: boolean = false;
  fileTableItems: any[] = [
  ]
  openedReport: string | null = null
  staticReports: any[] = []
  showReportModal: boolean = false
  selectedReport: any = null

  get showActions() {
    if (this.isFundManager) {
      return true
    }

    if (this.isFundManagerRole) {
      return this.$route.path.includes('fund-manager') ? true : false
    }

    if (this.isPortfolioCompany) {
      return !(this.activeUserData.context.context !== 'company' && this.options.length > 1)
    } else {
      return !(this.activeUserData.context.context !== 'fund' && this.options.length > 1);
    }
  }

  get showLePicker() {
    if (this.isFundManager) {
      return false
    }

    if (this.isPortfolioCompany) {
      return this.activeUserData.context.context !== 'company' && this.options.length > 1;
    } else {
      return this.activeUserData.context.context !== 'fund' && this.options.length > 1;
    }
  }

  get useViewedId() {
    if (this.isFundManager) {
      return false
    }

    if (this.isPortfolioCompany) {
      return this.activeUserData.context.context !== 'company';
    } else {
      return this.activeUserData.context.context !== 'fund';
    }
  }

  get hasStaticReports() {
    return !!(this.staticReports?.length > 0)
  }

  get isReportOpened() {
    return !!(this.openedReport)
  }

  get reportCommon() {
    return this.$store.getters['reporting/reportCommon'];
  }

  get reportFormPeriods() {
    return this.reportCommon?.reportFormPeriods;
  }

  get report() {
    return _.cloneDeep(this.$store.getters['reporting/report']);
  }

  get fileTableData() {
    return (data: any) => this.getFileTableData(data);
  }

  get globalHeaderFields() {
    const headerFields = [];

    for (let i = 0; i < this.report?.globalHeaders?.length; i++) {
      const header = this.report?.globalHeaders[i];
      headerFields.push({
        key: `header-${i}`,
        sortable: false,
        label: this.$options.filters.formatPeriod(header.date, header.period),
        labelInfo: header.status
      })
    }

    return [
      { key: 'name', sortable: false, label: '', labelInfo: null },
      ...headerFields,
    ]
  }

  get tableSections() {
    return this.report?.sections.filter((section: any) => section.type === 'TABLE');
  }

  get otherSections() {
    return this.report?.sections.filter((section: any) => section.type !== 'TABLE');
  }

  get editableValuesLength() {
    return Object.keys(this.editableValues).length;
  }

  async saveAndPublish(index?: number) {
    this.aggregateEditableValues();
    this.isLoading = true;
    
    try {
      const reportingId = this.viewedReportingId ? this.viewedReportingId : this.reportCommon?.reportFormPeriods.find((report: any) => report.name === this.report?.name)?.reportFormId;
      const dataToSend = Object.values(this.editableValues);
      const scenario = this?.report?.scenario;
      
      await this.$store.dispatch('reporting/postSaveReport', {
        reportingId,
        scenario,
        data: dataToSend,
        viewedContext: !this.isFundManager ? this.viewedId : '',
      });
      
      const unpublishedDates = this.report.globalHeaders?.map((header: any) => {
        return moment(header.date).format('YYYY-MM-DD');
      });

      const selectedDate = (index !== undefined && index !== null) ? moment(this.report?.globalHeaders[index - 1]?.date).format('YYYY-MM-DD') : false;

      await this.$store.dispatch('reporting/publishReport', {
        dates: selectedDate ? selectedDate : unpublishedDates.join(','),
        viewedContext: !this.isFundManager ? this.viewedId : '',
        reportingId,
        scenario
      });
      
      await new Promise((resolve: any) => setTimeout(() => {
        this.turnOffEditMode();
        resolve();
      }, 3000));

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
    }

    this.isLoading = false;
  }

  updateRadioValue(value: 1 | 0, id: string) {
    this.$store.commit('reporting/setReportingRadioRecordValue', {
      id, 
      value
    })
  }

  updateTextValue(value: any, id: string) {
    this.$store.commit('reporting/setReportingTextRecordValue', {
      id,
      value
    })
  }

  addFiles(uploadedFiles: any, id: string) {
    this.$store.commit('reporting/addReportingFiles', {
      id,
      uploadedFiles
    })

    this.$nextTick(() => {
      this.$root.$emit('bv::refresh::table');
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Files have been successfully uploaded'
      });
    });
  }

  getFileTableData(data: any) {
    if(data?.value?.files) {
      return data?.value;
    }

    return { files: [] };
  }

  aggregateEditableValues() {
    this.editableValues = {};

    this.report.sections.forEach((report: any) => {
      report.items.filter((re: any) => !re.abstract).forEach((record: any) => {
        record.values.filter((val: any) => val.editable).forEach((editableVal: any) => {
          editableVal.type = record.type;
          this.editableValues[editableVal.id] = editableVal;
        });
      })
    })
    this.aggregated = true;
  }

  setActiveColumn(index: number) {
    this.activeCol = index;
  }

  generateTableFields(section: any) {
    const headerFields = [];

    for (let i = 0; i < section?.headers.length; i++) {
      const header = section?.headers[i];
      headerFields.push({
        key: `header${i}`,
        sortable: false,
        label: this.$options.filters.formatPeriod(header.date, header.period),
        labelInfo: header.aggregation
      })
    }

    return [
      { key: 'name', sortable: false, label: '', labelInfo: null },
      ...headerFields,
    ]
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  turnOffEditMode() {
    this.editMode = false;
  }

  generateTableItems(section: any) {
    const itemFields = [];
    let itemHeaderFields: any = {};
    
    for (let i = 0; i < section?.items.length; i++) {
      const item = section?.items[i];

      for (let j = 0; j < item.values.length; j++) {
        const value = item.values[j];
        itemHeaderFields[`header${j}`] = value.value;
      }

      itemFields.push(
        {...{
          level: item.level,
          name: item.name,
          highlighted: item.abstract,
        },
        ...itemHeaderFields}
      )
    }

    return [
      ...itemFields,
    ]
  }

  scenarioHandler(value: string) {
    this.$store.commit('reporting/setReportScenario', value);
    this.refreshReport(this.editMode);
  }

  cancelHandler() {
    this.turnOffEditMode();
  }

  publishHandler(index?: number) {
    this.saveAndPublish(index);
  }

  prevHandler(dates: any) {
    this.changeReport(dates, 'prev');
  }

  nextHandler(dates: any) {
    this.changeReport(dates, 'next');
  }

  resetFilters() {
    this.periodType = '';
    this.rangeType = '';
  }

  closeReport() {
    this.openedReport = null
  }

  async editHandler() {
    if (this.editMode) {
      await this.saveChanges();
    }

    this.toggleEditMode();
  }

  async periodHandler(value: string) {

    if(this.editMode) {
      this.isLoading = true;
      try {
        const reportingId = this.viewedReportingId ? this.viewedReportingId : this.reportCommon?.reportFormPeriods.find((report: any) => report.name === this.report?.name)?.reportFormId;
        const scenario = this?.report?.scenario;
        await this.$store.dispatch('reporting/changePeriodType', {
          reportingId,
          scenario,
          periodType: value,
          viewedContext: !this.isFundManager ? this.viewedId : '',
        });
        await this.refreshReport(this.editMode);
        this.setActiveColumn(0);
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
      }
      this.isLoading = false;
      return;
    }

    this.periodType = value;
    await this.refreshReport(this.editMode);
    this.setActiveColumn(0);
  }

  async rangeHandler(value: string) {

    if (this.editMode) {
      this.isLoading = true;

      try {
        const reportingId = this.viewedReportingId ? this.viewedReportingId : this.reportCommon?.reportFormPeriods.find((report: any) => report.name === this.report?.name)?.reportFormId;
        const scenario = this?.report?.scenario;
        await this.$store.dispatch('reporting/changeRangeType', {
          reportingId,
          scenario,
          rangeType: value,
          viewedContext: !this.isFundManager ? this.viewedId : '',
        });
        await this.refreshReport(this.editMode);
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
      }
      this.isLoading = false;
      return;
    }

    this.rangeType = value;
    this.refreshReport(this.editMode);
  }

  async saveChanges() {
    this.aggregateEditableValues();
    this.isLoading = true;

    try {
      const reportingId = this.viewedReportingId ? this.viewedReportingId : this.reportCommon?.reportFormPeriods.find((report: any) => report.name === this.report?.name)?.reportFormId;
      const dataToSend = Object.values(this.editableValues);
      const scenario = this?.report?.scenario;
      
      await this.$store.dispatch('reporting/postSaveReport', {
        reportingId,
        scenario,
        data: dataToSend,
        viewedContext: !this.isFundManager ? this.viewedId : '',
      });
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
    }

    this.isLoading = false;
  }

  async changeReport(dates: any, type: 'next' | 'prev') {
    this.isLoading = true;

    try {
      const reportingId = this.viewedReportingId ? this.viewedReportingId : this.reportCommon?.reportFormPeriods.find((report: any) => report.name === this.report?.name)?.reportFormId;

      const date = type === 'next' ? moment(dates?.nextDate).format('YYYY-MM-DD') : moment(dates?.prevDate).format('YYYY-MM-DD');

      const scenario = this?.report?.scenario;

      await this.$store.dispatch(`reporting/${type === 'next' ? 'getNextReport' : 'getPrevReport'}`, {
        reportingId,
        date,
        scenario,
        periodType: this.periodType,
        rangeType: this.rangeType,
        viewedContext: !this.isFundManager ? this.viewedId : '',
        editMode: this.editMode,
      });
      this.aggregateEditableValues();
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
    }

    this.isLoading = false;
  }

  async refreshReport(editMode?: boolean) {
    this.isLoading = true;

    try {
      const reportingId = this.viewedReportingId ? this.viewedReportingId : this.reportCommon?.reportFormPeriods.find((report: any) => report.name === this.report?.name)?.reportFormId;
      const dateFrom = moment(this?.report?.dateFrom).format('YYYY-MM-DD');
      const dateTo = moment(this?.report?.dateTo).format('YYYY-MM-DD');
      const scenario = this?.report?.scenario;

      await this.$store.dispatch('reporting/getReport', {
        reportingId,
        dateFrom,
        dateTo,
        scenario,
        editMode,
        periodType: this.periodType,
        rangeType: this.rangeType,
        viewedContext: !this.isFundManager ? this.viewedId : '',
      });
      this.aggregateEditableValues();
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
    }

    this.isLoading = false;
  }

  async loadReport(item: any, resetActiveCol: boolean = false) {
    this.isLoading = true;
    let reportCommon = null;
    this.resetFilters();

    try {
      if(!item) {
        reportCommon = await this.$store.dispatch('reporting/getReportCommon', !this.isFundManager ? this.viewedId : '');
      }

      const reportingId = !item ? reportCommon.viewedReport.reportFormId : item.reportFormId;
      const dateFrom = moment()?.add(-3, 'quarters')?.format('YYYY-MM-DD')
      const dateTo = moment()?.format('YYYY-MM-DD')
      const scenario = !item ? reportCommon.viewedReport.scenario : item.scenario;

      // legacy
      // const dateFrom = !item ? moment(reportCommon.viewedReport.dateFrom).format('YYYY-MM-DD') : moment(item.dateFrom).format('YYYY-MM-DD');
      // const dateTo = !item ? moment(reportCommon.viewedReport.dateTo).format('YYYY-MM-DD') : moment(item.dateTo).format('YYYY-MM-DD');

      this.viewedReportingId = reportingId

      if (item) {
        this.openedReport = item.reportFormId
      }

      await this.$store.dispatch('reporting/getReport', {
        reportingId,
        dateFrom,
        dateTo,
        scenario,
        viewedContext: !this.isFundManager ? this.viewedId : ''
      });
      this.aggregateEditableValues();

      if (resetActiveCol) {
        this.setActiveColumn(0)
      }

    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
    }

    this.isLoading = false;
  }

  enableReportModal(item: any) {
    this.showReportModal = true
    this.selectedReport = item
  }

  closeReportModal() {
    this.showReportModal = false
    this.selectedReport = null
  }

  downloadSelectedReportFile() {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${this.selectedReport?.filePath}`, this.selectedReport?.name || '')
  }

  async checkReportingAvailability(id: string) {
    const leAccessModules = await this.$store.dispatch('genprox/getLegalEntityAccessModules', id);
    if(!leAccessModules?.managementReporting) {
      this.$router.push({
        name: 'reporting-access-denied'
      })
    } else {
      localStorage.setItem(REDIRECTED_PATH, window.location.pathname)
      if(this.isPortfolioCompany) {
        await this.$store.dispatch('auth/changeViewedCompany', id);
      } else {
        await this.$store.dispatch('auth/changeViewedFund', id);
      }
      await this.loadMissingData();
      this.loadReport(null);
    }
  }

  async loadStaticReports() {
    const staticReports = await this.$store.dispatch('reporting/getStatutoryReports', !this.isFundManager ? this.viewedId : '')

    this.staticReports = staticReports || []
  }

  async beforeMount() {
    const promises = [this.loadMissingData(), this.loadStaticReports()]

    await Promise.all(promises)

    this.loadReport(null);
  }

  @Watch('editMode') async onEditModeChange() {
    await this.refreshReport(this.editMode);
  }
}
